<template>
  <div class="accessories-page">
    <van-nav-bar title="配件价格查询" left-arrow  @click-left="$router.go(-1)"/>
    <div class="content-padding">
      <field-picker v-model="query.category_id" label="产品分类:" placeholder="请选择产品分类" :options="categories" @change="loadProducts"></field-picker>
      <field-picker v-model="query.product_id" label="产品:" placeholder="请选择产品" :options="products"></field-picker>
      <van-button type="primary" size="large" class="submit" @click="queryData">立即查询</van-button>

      <table class="part-table" v-show="data.length>0">
        <thead>
          <tr>
            <td>配件</td>
            <td>价格（元）</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in data" :key="index">
            <td>{{ row.name }}</td>
            <td>{{ row.price }}</td>
          </tr>
        </tbody>
      </table>

      <div class="tip">
        <p>温馨提示：</p>
        <p>1、以上内容为更换零配件的参考价，实际价格以工程师检测确认为准。<br/>
        2、对于保内机器不收取任何费用，对于保外机器维修仅收取零配件费用（不含跑步机），不收取人工费。<br/>
        3、保外维修更换后的部件、充电器享有 90 天的有限保修期（鞋服类除外）；<br/>
        4、需要寄回（不含跑步机）产品（邮费自理），经售后检测后给出相关报价，如果您同意该报价，并支付相应的费用，Amazfit售后将为您提供产品维修服务；如果您拒绝付费，Amazfit售后将会自费将您的产品原物寄还。<br />
        5、跑步机保外维修，除零配件费用外，还需收取上门服务费（含上门费+修理费）。<br />
        6、付费维修的坏料将由Amazfit公司回收进行专业环保处理，不再返还。</p>
      </div>
    </div>
  </div>
</template>

<script>
import FieldPicker from '../../components/FieldPicker.vue'

export default {
  name: 'accessories',
  components: {
    FieldPicker
  },
  data () {
    return {
      categories: [],
      products: [],
      data: [],
      query: {}
    }
  },
  created () {
    this.loadCategories()
  },
  methods: {
    loadCategories () {
      this.$http.get('https://sale.huami.com/v1/product-categories').then((res) => {
        this.categories = res.data
      })
    },
    loadProducts () {
      this.$http.get('https://sale.huami.com/v1/products', { params: { category_id: this.query.category_id } }).then((res) => {
        this.products = res.data
      })
    },
    queryData () {
      if (!this.query.category_id || !this.query.product_id) {
        this.$toast.fail('请选择条件后再查询')
        return
      }

      this.$http.get('https://sale.huami.com/v1/parts-costs', { params: this.query }).then((res) => {
        this.data = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.accessories-page {
  select {
    margin-bottom: 20px;
    padding-left: 20px;
    width: 100%;
    height: 50px;
    border-radius: 6px;
    outline: none;
    font-size: 30px;
  }

  .tip {
    margin-top: 40px;
  }

  table {
    margin-top: 20px;
    min-width: auto;
    width: 100%;
    border: 1px solid #bcbcbc;
    border-collapse: collapse;

    td {
      padding: 10px 0;
      width: 50%;
      border-bottom: 1px solid #bcbcbc;
      text-align: center;

      &:first-child {
        border-right: 1px solid #bcbcbc;
      }
    }
  }
}
</style>
